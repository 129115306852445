.swiper {
  width: 100%;
  height: 250px;
}

.swiper-wrapper {
  height: 250px;
  gap: 20px;
  justify-content: center;
}

.swiper-button-next {
  top: 110px !important;
  color: #333 !important;
  width: 40.83px !important;
  height: 20.42px !important;
}

.swiper-button-prev {
  top: 110px !important;
  color: #333 !important;
  width: 40.83px !important;
  height: 20.42px !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex !important;
  align-items: start;
  border-radius: 10px;
  height: 200px;
  width: 200px !important;
  justify-content: center;
  border: 1px solid #cbcbcb63;
}

.img-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.choose-ds {
  width: 400px;
  padding: 0 50px;
  margin: auto;
}

@media screen and (max-width: 867px) {
  #gif {
    display: none;
  }
  body {
    overflow: auto !important;
  }
}

@media screen and (max-width: 467px) {
  .loginBtn {
    flex-flow: nowrap;
  }
}
