.campaign-img {
  height: 115.8px;
  width: 276px;
  border-radius: 6px 0 0 6px;
}

/* add new brand */
.add-new-brand {
  height: 150px;
  width: 150px;
  border: 1px solid #cbcbcb38;
  background: #fff;
  border-radius: 6px;
  margin: auto;
  padding-top: 30px;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@media only screen and (max-width: 1280px) {
  .campaign-img {
    width: 240px;
  }
}

/* alignments */
.end {
  text-align: end;
}

/*welcome page setup css*/

.wrapper-div {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  justify-content: center;
}

.logo-image {
  height: 70px;
}

.nav-div {
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.who {
  font-size: 22px;
  font-weight: 500;
  margin-top: 30px;
}

.choose-brand {
  font-size: 16px;
  width: 500px;
  margin-top: 10px;
}

.wrapper-container {
  display: flex;
  justify-content: space-evenly;
}

.brand-div {
  height: 100px;
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.otpInput {
  width: 40px;
  height: 40px;
  margin: 3px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #8f1c82;
}

.brand-outer {
  position: relative;
  height: 200px;
  width: 30%;
  background-color: #fff;
  margin: 35px 0;
  border: 1px solid #cbcbcb38;
  border-radius: 20px;
}

.admin-status {
  width: 60%;
  padding: 7px 0;
  margin: 0 auto;
  color: #f04747;
  background-color: #ffffff;
  border: 1px solid #ff8585;
  border-radius: 10px;
}

.admin-approve-status {
  height: 40px;
  width: 60%;
  padding: 10px 0;
  margin: 0 auto;
  color: #4caf50;
  background-color: #a9ebac;
}

.create-brand-button {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: calc(100% - 30px);
}

.create-company-button {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: calc(100% - 30px);
}

.bottom-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
}

.man {
  margin-top: -80px;
  height: 585px;
  width: 606px;
  margin-left: -260px;
}

.man-image {
  height: inherit;
  width: inherit;
}

.div-circle {
  height: 200px;
  width: 120px;
  border-radius: 50%;
  background-color: #8f1c82;
  translate: -110% -35%;
  opacity: 0.6;
}

/* welcome page role css */

.select-brand-div {
  padding: 10px;
  height: auto;
  margin: auto 0;
  width: inherit;
}

.outer-brand-div {
  width: 80%;
  margin: auto;
  height: calc(100% - 120px);
  display: flex;
  justify-content: center;
}

.brand-name {
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  bottom: 20px;
}

.outer-brand-status {
  position: absolute;
  bottom: 45px;
  display: flex;
}

.brand-status-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #28967e;
}

.brand-status {
  display: flex;
  font-size: 10px;
  width: 70px;
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  justify-content: center;
  color: #fff;
}

/* agency-name superadmin */

.agency-name-brand {
  width: fit-content;
  height: 50px;
  background-color: #e172d470;
  color: #8f1c82;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 12px 10px;
  border-radius: 10px;
}

/* invitation css */

.wrapper-verify {
  margin: 10px 0;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
}

.outer-verify-div {
  width: 70%;
  height: auto;
  display: flex;
  background-color: #fff;
}

.line-box {
  height: 100%;
  width: 75px;
  display: flex;
}

.green-line {
  background-color: #349782;
  width: 25px;
  height: 100%;
  margin-right: 4px;
}

.purple-line {
  height: 100%;
  background-color: #901c81;
  width: 20px;
  margin-right: 4px;
}

.yellow-line {
  background-color: #f7b719;
  width: 10px;
  height: 100%;
}

.verismart-logo {
  height: 60px;
}

.image-verify-logo {
  height: 60px;
  width: 210px;
  margin-top: 10px;
}

.business-image {
  /* height: 80%; */
  width: 15%;
}

.business-heading {
  font-weight: 700;
  font-size: 40px;
  color: #333;
}

.image-text-div {
  display: flex;
  flex-direction: column;
}

.congrat-text {
  font-size: 25px;
  font-weight: 800;
  margin-top: 20px;
}

.congrat-text-2 {
  font-size: 17px;
  font-weight: 500;
  color: #6d6d6d;
  margin-top: 20px;
}

.verify-button {
  color: #2196f3;
  cursor: pointer;
  font-size: 16px;
}

.card-brand-div {
  height: 50px;
  width: 50px;
}

.card-brand-image {
  height: 100px;
  width: 100px;
}

.card-company-image {
  height: 100px;
  width: 100px;
}

.card-heading {
  font-size: 20px;
  font-weight: 500;
}

.verify-btn {
  width: 75%;
  padding: 13px;
  font-size: 15px;
  background: #edbc00;
  color: black;
  font-weight: 800;
  margin-top: 20px;
}

/*selct role  */

.edit-icon {
  height: 25px;
  position: absolute;
  bottom: 20px;
  right: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid #8f1c82;
  cursor: pointer;
}

.edit-icon:hover {
  transform: scale(1.04);
}

@media only screen and (max-width: 450px) {
  .congrat-text {
    font-size: 20px;
  }

  .congrat-text-2 {
    font-size: 13px;
  }
  .verify-btn {
    font-size: 12px;
  }
}

/* invitation page media query */

@media only screen and (max-width: 840px) {
  /* For mobile phones: */
  .wrapper-verify {
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    padding: 0 10px;
  }

  .line-box {
    display: none;
  }

  .outer-verify-div {
    width: 100%;
    height: auto;
    display: flex;
    background-color: #fff;
  }

  .image-verify-logo {
    height: 50px;
    width: 180px;
    float: none;
  }

  .business-heading {
    font-weight: 700;
    font-size: 25px;
    color: #333;
  }

  .image-text-div {
    display: block;
  }

  .business-image {
    height: 50%;
    width: 20%;
    margin: auto;
  }

  .image-business {
    text-align: center;
  }
}

.brand-switch-logo {
  background-color: #e172d470;
  color: #8f1c82;
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

/* upload company document css */

.div-upload-document {
  height: auto;
  align-self: stretch;
  width: 300px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed rgb(189 189 189 / 84%);
}

.file-chip {
  margin-top: 10px;
}

/* campaign list page */

.card-campaign-list {
  margin: 8px 0;
  background-color: #fff;
  border-radius: 10px;
}

/* button div */

.add-new-logo {
  padding: 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

/* explore connection */

.explore-connection-div {
  background-color: #28967e;
  padding: 20px 20px 0;
  border-radius: 10px;
}

.explore-connection-text {
  font-size: 16px;
  color: #fff;
}

.explore-connection-button {
  width: 200px;
  text-align: center;
  background-color: #fff;
  padding: 15px;
  margin-top: 10px;
  border-radius: 25px;
  color: #28967e;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.explore-connection-button:hover {
  transform: scale(1.04);
}

.explore-connection-image {
  height: 100px;
  margin-right: -40px;
  margin-bottom: -25px;
}

/* signup / login */

.font {
  font-size: 14px;
  font-weight: 500;
}

/* connection list */

.match-mandate-chip {
  color: #fff;
  background-color: #028fd1 !important;
  height: 35px;
  font-size: 15px;
}

.connect-now {
  color: #fff;
  background-color: #901c81 !important;
  height: 35px;
  font-size: 15px;
  cursor: pointer;
}

.extra-chip {
  color: #fff;
  background-color: #028fd1 !important;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  align-self: center;
}

.unique-user-count {
  color: #fff;
  background-color: #28967e !important ;
  height: 40px;
  padding-inline: 5px;
  font-size: 15px;
}

.match-needed {
  color: #fff;
  background-color: #028fd1 !important ;
  height: 40px;
  padding-inline: 5px;
  font-size: 15px;
}

.campaign-status {
  color: #fff;
  background-color: #8f1c82 !important ;
  height: 40px;
  padding-inline: 5px;
  font-size: 15px;
}

/* credit page */

.credit-box {
  height: auto;
  width: 300px;
  padding: 20px;
  background-color: #8f1c82;
  color: #fff;
  border-radius: 10px;
}

/* stats */

.headers-icon {
  font-size: 20px;
  position: relative;
  top: 4px;
}

.wa-icon {
  color: #28967e;
  position: relative;
  top: -1px;
  font-size: 30px;
  margin-right: 3px;
}

.fb-icon {
  color: rgb(2, 143, 209);
  position: relative;
  top: -1px;
  font-size: 30px;
  margin-right: 3px;
}

.google-icon {
  position: relative;
  top: -0px;
  font-size: 30px;
  margin-right: 3px;
}

.paper {
  border-radius: 4px;
  height: 200px;
}

.sent-message {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
}

.heading-main {
  font-size: 16px;
  margin-top: 12px;
}

.table-cell-text {
  display: flex;
  align-self: center;
  font-size: 16px;
  font-weight: 500;
}

.blue-icon {
  background-color: #028fd1;
}

.red-icon {
  background-color: #d32f2f;
}

.yellow-icon {
  background-color: #f3b61f;
}

.green-icon {
  background-color: #28967e;
}

.numbers {
  font-weight: 500;
  margin-top: 12px;
  font-size: 20px;
}

.sub-heading {
  font-size: 14px;
  margin-top: 12px;
  color: #bebebe;
}

.icons {
  position: absolute;
  top: 16px;
  left: 16px;
  color: white;
  font-size: 18px;
}

/* agencies-brand-list-superadmin */

.agencies-brand-div {
  display: flex;
  justify-content: space-between;
  width: 300px;
  height: 40px;
  padding: 6px 10px;
  border: 1px solid #bebebe;
  border-radius: 4px;
}

.brand-name-agency {
  color: #8f1c82;
  font-size: 16px;
  font-weight: 500;
}

.eye-icon {
  color: #8f1c82;
}

/* facebook */

.facebook-table-icon {
  display: flex;
}

/*colors*/

.purple {
  color: #901c81;
}

.info {
  color: #2196f3;
}

.error {
  color: #d32f2f;
}

.warning {
  color: #ff9800;
}

.success {
  color: #4caf50;
}
